/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

function Seo({ lang, meta, title, description, keywords, noIndex, noFollow, slug, canonicalUrl, ogTitle, ogType, ogDescription, ogImage }) {
  const defaultBrand = " - Allency agencia de publicidad";

  const metaOgTitle = ogTitle || title;
  const completeTitle = title + defaultBrand;
  const completeMetaOgTitle = metaOgTitle + defaultBrand;
  const metaDescription = description;
  const metaOgDescription = ogDescription;
  const metaOgType = ogType || "website";
  const metaOgImage = ogImage || "";
  const metaKeywords = keywords ? keywords.join() : "";
  const url = canonicalUrl;

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={completeTitle}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: completeMetaOgTitle,
          },
          {
            property: `og:description`,
            content: metaOgDescription,
          },
          {
            property: `og:type`,
            content: metaOgType,
          },
          {
            property: `og:image`,
            content: metaOgImage,
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          },
          {
            name: `twitter:site`,
            content: `@allency`,
          },
          {
            name: `twitter:creator`,
            content: "@allency",
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:image`,
            content: metaOgImage,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
          {
            name: "keywords",
            content: metaKeywords,
          },
        ].concat(meta)}
        link={[{ rel: "canonical", href: url }]}
      />
      {noIndex && (
        <Helmet
          meta={[
            {
              name: "robots",
              content: "noindex",
            },
          ]}
        />
      )}
      {noFollow && (
        <Helmet
          meta={[
            {
              name: "robots",
              content: "nofollow",
            },
          ]}
        />
      )}
    </>
  );
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  title: ``,
  description: ``,
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  noIndex: PropTypes.bool,
  noFollow: PropTypes.bool,
  ogTitle: PropTypes.string,
  ogType: PropTypes.string,
  ogDescription: PropTypes.string,
  ogImage: PropTypes.string,
};

export default Seo;
