import React, { useState } from 'react'
import theme from '../utils/theme'
import Slider from 'react-slick'
import styled from 'styled-components'

import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'

const StyledSlider = styled(Slider)`
  * {
      outline: none !important;
    }

  ${p => p.overflow && `
    .slick-list {
      overflow: visible;
      // @media (max-width: 640px) {
      //   overflow: hidden;
      // }
    }
  `}
`

const CustomNext = styled.div`
  display: block;
  position: absolute;
  top: 45%;
  left: 100%;
  cursor: pointer;
`
const CustomIconRight = styled(BiChevronRight)`
  font-size: 50px;
  color: ${theme.colors.extended.gray400};
  &:hover {
    color: ${theme.colors.extended.gray300};
  }
`

const CustomPrev = styled.div`
  display: block;
  position: absolute;
  top: 45%;
  right: 100%;
  cursor: pointer;
`
const CustomIconLeft = styled(BiChevronLeft)`
  font-size: 50px;
  color: ${theme.colors.extended.gray400};
  &:hover {
    color: ${theme.colors.extended.gray300};
  }
`

const NextArrow = (props) => {
  const { onClick } = props
  return (
    <CustomNext className="slick-arrow" onClick={onClick}>
      <CustomIconRight />
    </CustomNext>
  )
}

const PrevArrow = (props) => {
  const { onClick } = props
  return (
    <CustomPrev className="slick-arrow" onClick={onClick}>
      <CustomIconLeft />
    </CustomPrev>
  )
}


const CustomSlider = ({ toShow = [3,2,1], toScroll = [3,2,1], dots = false, arrows = true, children, overflow = false}) => {
  const [hideArrows, setHideArrows] = useState(arrows)
  
  const settings = {
    dots: !hideArrows && dots,
    infinite: true,
    speed: 500,
    slidesToShow: toShow[0],
    slidesToScroll: toScroll[0],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 832,
        settings: {
          slidesToShow: toShow[1],
          slidesToScroll: toScroll[1],
          onReInit: () => setHideArrows(true),
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: toShow[2],
          slidesToScroll: toScroll[2],
          onReInit: () => setHideArrows(false),
        },
      },
    ],
  }

  return (
    <>
      <StyledSlider {...settings} arrows={hideArrows} overflow={overflow}>
        {children}
      </StyledSlider>
    </>
  )
}

export default CustomSlider
