import React, { useEffect } from 'react'
import styled from 'styled-components'
import theme from '../utils/theme'
import ReactModal from 'react-modal' // https://reactcommunity.org/react-modal/

ReactModal.setAppElement('#___gatsby')

const Close = styled.div `
  position: absolute;
  width: 20px;
  height: 20px;
  top: 2px;
  right: 2px;
  background: ${theme.colors.secondary.red700};
  border-radius: 4px;
  cursor: pointer;
  z-index: 999;
  &::before {
    position: absolute;
    content: '';
    width: 18px;
    height: 2px;
    top: calc(50% - 1px);
    left: 50%;
    background: ${theme.colors.light};
    transform: translate(-50%) rotate(45deg);
  }
  &::after {
    position: absolute;
    content: '';
    width: 18px;
    height: 2px;
    top: calc(50% - 1px);
    left: 50%;
    background: ${theme.colors.light};
    transform: translate(-50%) rotate(-45deg);
  }
`
const Modal = ({ isOpen, setClose, children, className, customStyles }) => {
  useEffect(() => {
    if (isOpen) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'unset'
  })

  return (
      <ReactModal
        className={className}
        isOpen={isOpen}
        onRequestClose={() => setClose()}
        style={{
          overlay: { overflow: 'auto', backgroundColor: 'rgba(0, 0, 0, 0.50)', zIndex: '99999' },
          content: customStyles,
        }}
      >
        <Close onClick={() => setClose()} />
        {children}
      </ReactModal>
  )
}
export default Modal

// <Modal isOpen={true} setClose={() => setModal(false)} customStyles={modalStyles}> </Modal>
// const modalStyles = {
//   border: 'none',
//   padding: '0',
//   overflow: 'hidden',
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   background: 'none',
// }