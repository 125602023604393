import React from 'react'
import styled from 'styled-components'
import theme from '../../utils/theme'

import Button from '../../components/Button'
import Section from '../../components/Section'
import Container from '../../components/Container'
import Typography from '../../components/Typography'
import { Flex, Box } from '../../components/Grid'
import { GatsbyImage } from 'gatsby-plugin-image'

const StyledSection = styled(Section)`
  position: relative;
  background: linear-gradient(135deg, rgba(255,255,255,1) 0%, rgba(234,234,234,0.5) 100%);
  overflow: hidden;
  height: 100vh;
  &::before {
    position: absolute;
    content: '';
    top: 0;
    right: -25%;
    transform: translateX(25%) rotate(-45deg);
    background: ${theme.colors.extended.gray400};
    background-size: cover;
    background-repeat: no-repeat;
    width: 800px;
    height: 1800px;
    filter: brightness(1.2);
    opacity: 0.5;
    border-radius: 400px;
    z-index: -1;
  }
`

const Rocket = styled(Box)`
  @media (max-width: 832px) {
    position: absolute;
    width: 100%;
    right: -30%;
    transform: rotate(325deg);
    opacity: 0.7;
    z-index: -1;
  }
`

const Form = styled.form``
const Input = styled.input``
const Textarea = styled.textarea``

const Contact = ({ contents }) => {
  return (
    <StyledSection>
      <Container>
        <Box px={0} textAlign='center'>
          <Typography tag="span" mb={3} color={theme.colors.primary} fontSize={3} fontFamily="Graphik-Medium" textTransform="uppercase" letterSpacing="2px" >
            Contáctanos
          </Typography>
          <Typography tag="h3">Comencemos juntos</Typography>
          <Typography tag="p" mb={[5,5,7]}>Estamos listos para que despeguemos tu proyecto juntos<br/> y brindarte las soluciones que estás buscando.</Typography>
        </Box>
        <Box px={0}>
          <Flex position='relative'>
            <Rocket width={['25%']} >
              <GatsbyImage image={contents.image} alt='Launch your project with Allency' />
            </Rocket>
            <Box px={[0,0,3]} width={['100%','100%','75%']}>
              <Form action="https://submit-form.com/y0TsOGAM">
                <Flex>
                  <Box px={[0,0,3]} py={2} width={['100%','100%','50%']}><Input type='text' name='Name' id='name' placeholder='Nombre' /></Box>
                  <Box px={[0,0,3]} py={2} width={['100%','100%','50%']}><Input type='email' name='Email' id='email' placeholder='E-mail' /></Box>
                  <Box px={[0,0,3]} py={2} width={['100%','100%','50%']}><Input type='text' name='Subject' id='subject' placeholder='Asunto' /></Box>
                  <Box px={[0,0,3]} py={2} width={['100%','100%','50%']}><Input type='phone' name='Phone' id='phone' placeholder='Teléfono' /></Box>
                  <Box px={[0,0,3]} py={2} width={['100%','100%','100%']}><Textarea name='Message' id='message' placeholder='Mensaje' /></Box>
                </Flex>
                <Box px={[0,0,3]}><Button type='submit' px={5} variant='primary' letterSpacing='3px'>ENVIAR</Button></Box>
              </Form>
            </Box>
          </Flex>
        </Box>

      </Container>
    </StyledSection>
  )
}

export default Contact