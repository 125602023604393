import React from "react";
import styled from "styled-components";
import theme from "../utils/theme";

import Hr from "../components/Hr";
import Container from "../components/Container";
import MediaShare from "./MediaShare";
import Typography from "../components/Typography";
import { Flex } from "../components/Grid";

const StyledFooter = styled.footer`
  background: ${theme.colors.extended.gray900};
  padding-top: 54px;
  padding-bottom: 54px;
`

const Footer = () => {
  return (
    <StyledFooter>
      <Container>
        <Flex color={theme.colors.light} justifyContent="center" alignItems="center" flexDirection="column">
          <Typography mb={2} color={theme.colors.extended.gray500}>
            Tus aliados en publicidad y estrategia
          </Typography>
          <MediaShare size={"24px"} />
        </Flex>
        <Hr mt={3} mb={3} variant="horizontal" background={theme.colors.extended.gray800} />
        <Flex color={theme.colors.light} justifyContent="center" alignItems="center">
          <Typography mb={0} color={theme.colors.extended.gray700}>
            2022 - All Right Reserved.
          </Typography>
        </Flex>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
