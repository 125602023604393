// static
import React from "react"
import styled from "styled-components"
import theme from "../../utils/theme"

import Link from "../../components/Link"
import Image from "../../components/Images"
import Section from "../../components/Section"
import Container from "../../components/Container"
import Typography from "../../components/Typography"
import { Flex, Box } from "../../components/Grid"

import strategy from '../../images/icons/service-strategy.svg'
import mktDigital from '../../images/icons/service-mkt-digital.svg'
import webDevelopment from '../../images/icons/service-web-development.svg'
import branding from '../../images/icons/service-branding.svg'

const StyledSection = styled(Section)`
  position: relative;
  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: -25%;
    transform: translateX(25%) rotate(45deg);
    background: ${theme.colors.extended.gray400};
    background-size: cover;
    background-repeat: no-repeat;
    width: 800px;
    height: 1800px;
    // filter: brightness(1.4);
    // opacity: 0.4;
    filter: brightness(1.2);
    opacity: 0.3;
    border-radius: 400px;
    z-index: -1;
  }
`

const Card = styled.div`
  position: relative;
  width: 100%;
  min-height: 340px;
  padding: 24px;
  color: ${theme.colors.light};
  border-radius: 8px;
  // background: linear-gradient(135deg, rgba(242, 134, 39, 1) 0%, rgba(242, 202, 38, 1) 100%);
  background: linear-gradient(135deg, rgba(149,185,53,1) 0%, rgba(216,249,128,1) 100%);
  box-shadow: 0 0 30px -5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  cursor: default;

  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(225deg, rgba(149,185,53,1) 0%, rgba(216,249,128,1) 100%);
    transition: opacity 0.3s ease-in-out;
    border-radius: 8px;
    z-index: -1;
    opacity: 0;
  }
  &:hover {
    transform: scale(1.05);
    &::before {
      opacity: 1;
    }
  }
`

const ServiceSection = () => {
  return (
    <StyledSection pt={'48px'} overflow='hidden' id='services' >
      <Container >
        <Flex justifyContent='space-between'>
          <Box px={0} pr={[0,0,3]} pt={[0,0,5]} pb={[5,5,0]} width={['100%','100%','40%']}>
            <Typography tag="span" mb={3} color={theme.colors.primary} fontSize={3} fontFamily={1} textTransform="uppercase" letterSpacing="2px" >
              Servicios
            </Typography>
            <Typography tag="h3">Servicios que brindan soluciones</Typography>
            <Typography tag="p" mb={4}>
              Estamos comprometidos en obtener los resultados que tu negocio necesita, es por eso que contamos con servicios especializados que te ofrecerán soluciones integrales.
            </Typography>
            <ul>
              <li>Gestionamos tu proyecto</li>
              <li>Servicios orientados en resultados</li>
              <li>Preparamos planes de crecimiento</li>
            </ul>
            <Link href={'/services'} variant='button-primary' color={theme.colors.light} >Servicios</Link>
          </Box>
          <Box px={0} pl={[0,0,3]} width={['100%','100%','55%']}>
            <Flex>

              <Box px={0} pr={[0,3]} pt={[4,5]} width={['100%',1/2]}>
                <Card>
                  <Image image={branding} alt='Branding' width={'70px'} />
                  <Typography tag='h4' mt={2}>Branding e imagen</Typography>
                  <Typography tag='p' mb={0} color={theme.colors.light} >Es el proceso más importante para la construcción de la identidad, imagen y comunicación de tu marca. </Typography> 
                </Card>
              </Box>
              <Box px={0} pl={[0,3]} pt={[4,0]} width={['100%',1/2]}>
                <Card>
                  <Image image={webDevelopment} alt='Web development' width={'70px'} />
                  <Typography tag='h4' mt={2}>Desarrollo web</Typography>
                  <Typography tag='p' color={theme.colors.light} >Realizamos desde el esqueleto de tu sitio hasta la generación del diseño web y programación.</Typography> 
                </Card>              
              </Box>
              <Box px={0} pr={[0,3]} pt={[4,5]} width={['100%',1/2]}>
                <Card>
                  <Image image={strategy} alt='Strategy' width={'70px'} />
                  <Typography tag='h4' mt={2}>Publicidad estratégica</Typography>
                  <Typography tag='p' color={theme.colors.light} >Generamos la estrategia que se puede llevar a la acción de acuerdo a tu tipo de proyecto y presupuesto.</Typography> 
                </Card>
              </Box>
              <Box px={0} pl={[0,3]} pt={[4,0]} width={['100%',1/2]}>
                <Card>
                  <Image image={mktDigital} alt='Digital marketing' width={'70px'} />
                  <Typography tag='h4' mt={2}>Marketing digital</Typography>
                  <Typography tag='p' color={theme.colors.light} >Generamos estrategias digitales para tu marca a través de campañas en diferentes plataformas.</Typography> 
                </Card>
              </Box>
              
            </Flex>
          </Box>
        </Flex>
      </Container>
    </StyledSection>
  );
};

export default ServiceSection;
