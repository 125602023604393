// semi-dynamic
import React from "react"
import styled from "styled-components"
import theme from "../../utils/theme"

import Image from "../../components/Images"
import Section from "../../components/Section"
import Container from "../../components/Container"
import Typography from "../../components/Typography"
import { Flex, Box } from "../../components/Grid"
import { GatsbyImage } from "gatsby-plugin-image"

const StyledSection = styled(Section)`
  position: relative;
  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: -30%;
    transform: translateX(25%) rotate(45deg);
    background: ${theme.colors.extended.gray400};
    background-size: cover;
    background-repeat: no-repeat;
    width: 800px;
    height: 2000px;
    filter: brightness(1.2);
    opacity: 0.3;
    border-radius: 400px;
    z-index: -1;
  }
  &::after {
    position: absolute;
    content: '';
    top: 50%;
    right: -30%;
    transform: translateX(25%) rotate(-45deg);
    background: ${theme.colors.extended.gray400};
    background-size: cover;
    background-repeat: no-repeat;
    width: 800px;
    height: 2000px;
    filter: brightness(1.2);
    opacity: 0.3;
    border-radius: 400px;
    z-index: -1;
  }
`

const ServiceSection = ({ contents }) => {
  return (
    <StyledSection pt={'48px'} overflow='hidden' id='services' >
      <Container>
        <Box px={0} textAlign='center'>
          <Typography tag="span" mb={3} color={theme.colors.primary} fontSize={3} fontFamily={1} textTransform="uppercase" letterSpacing="2px" >Servicios</Typography>
          <Typography tag="h3">Servicios que<br/> brindan soluciones</Typography>
          <Typography tag="p" mb={6}>Contamos con servicios especializados<br/> que te ofrecerán soluciones integrales.</Typography>
        </Box>
        {contents.items.map((element, i) => (
          <Flex mb={5} justifyContent='space-between' key={i}>
            <Box px={0} width={['100%','100%','50%']} order={i % 2 === 0 ? [null, null, null, -1] : [null, null, null, 1]}>
              <GatsbyImage image={element.image} alt={element.alt} />
            </Box>
            <Box px={[0,0,3]} width={['100%','100%','50%']}>
              <Image variant='whiteToPrimary' image={element.icon} alt={element.title} width={'70px'} />
              <Typography tag="h3">{element.title}</Typography>
              <Typography tag="p" mb={4} dangerouslySetInnerHTML={{ __html: element.description }}/>
              <ul>
                {element.list.map(list => <li className="list-dot-arrow" key={i}>{list}</li>)}
              </ul>
            </Box>
          </Flex>
        ))}
      </Container>
    </StyledSection>
  );
};

export default ServiceSection;