import React from "react"
import styled from "styled-components"
import theme from "../../utils/theme"

import Link from "../../components/Link"
import Image from "../../components/Images"
import Section from "../../components/Section"
import Container from "../../components/Container"
import Typography from "../../components/Typography"
import { Flex, Box } from "../../components/Grid"

import support from '../../images/icons/allency-support-areas-management.svg'
import mktDigital from '../../images/icons/allency-support-areas-marketing.svg'
import webDevelopment from '../../images/icons/allency-support-areas-programming.svg'
import design from '../../images/icons/allency-support-areas-design.svg'

const Card = styled.div`
  position: relative;
  width: 100%;
  min-height: 320px;
  padding: 24px;
  border-radius: 8px;
  background: linear-gradient(135deg, rgba(225,225,225,1) 0%, rgba(235,235,235,1) 100%);
  box-shadow: 0 0 30px -5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  cursor: default;

  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(45deg, rgba(225,225,225,1) 0%, rgba(235,235,235,1) 100%);
    transition: opacity 0.3s ease-in-out;
    border-radius: 8px;
    z-index: -1;
    opacity: 0;
  }
  &:hover {
    transform: scale(1.05);
    &::before {
      opacity: 1;
    }
  }
`

const Index = ({ contents }) => {
  return (
    <Section background={theme.colors.extended.gray200}>
      <Container>
        <Flex justifyContent='space-between'>
          <Box px={0} pr={[0,0,3]} width={['100%','100%','55%']}>
            <Flex>

              <Box px={0} pr={[0,3]} pt={[4,0]} width={['100%',1/2]}>
                <Card>
                  <Image variant='toDark' image={support} alt='Support management' width={'70px'} />
                  <Typography tag='h4' mt={2}>Gestión de proyectos</Typography>
                  <Typography tag='p' >Trabaja en compañía de un project manager para el desarrollo y seguimiento de las actividades en cada fase de tu proyecto, además de la revisión de tiempos y avances.</Typography> 
                </Card>              
              </Box>
              <Box px={0} pl={[0,3]} pt={[4,5]} width={['100%',1/2]}>
                <Card>
                  <Image variant='toDark' image={mktDigital} alt='Marketing' width={'70px'} />
                  <Typography tag='h4' mt={2}>Marketing</Typography>
                  <Typography tag='p' >Nuestro equipo se encargará de definir qué planes estratégicos llevar a la acción para tu proyecto, seleccionar el tipo de materiales de comunicación necesarios y del monitoreo diario de las campañas.</Typography> 
                </Card>
              </Box>
              <Box px={0} pr={[0,3]} pt={[4,0]} width={['100%',1/2]}>
                <Card>
                  <Image variant='toDark' image={webDevelopment} alt='Desarrollo web' width={'70px'} />
                  <Typography tag='h4' mt={2}>Programación</Typography>
                  <Typography tag='p' >En esta área nos encargamos de revisar toda la cuestión de ejecución y programación web. Nos aseguramos de brindarte la mayor calidad estética y de funcionalidad de acuerdo a las necesidades de tu sitio.</Typography> 
                </Card>
              </Box>
              <Box px={0} pl={[0,3]} pt={[4,5]} width={['100%',1/2]}>
                <Card>
                  <Image variant='toDark' image={design} alt='Support management' width={'70px'} />
                  <Typography tag='h4' mt={2}>Diseño e imagen</Typography>
                  <Typography tag='p' >Tendrás el apoyo de expertos para brindarle una imagen profesional a tu marca, transmitir el mensaje de manera correcta y cumplir con los objetivos de comunicación.</Typography> 
                </Card>
              </Box>
              
            </Flex>
          </Box>
          <Box px={0} pl={[0,0,3]} pt={[0,0,5]} pb={[5,5,0]} width={['100%','100%','40%']}>
            <Typography tag="h3">Áreas de apoyo para aliados</Typography>
            <Typography tag="p" mb={3} color={theme.colors.primary} fontSize={3} fontFamily={1} textTransform="uppercase" letterSpacing="2px" >
              Brindamos soluciones integrales
            </Typography>
            <Typography tag="p" mb={4}>
              Cuenta con el apoyo de profesionales en nuestras áreas para generartes las soluciones que esperas dentro de tu proyecto.
            </Typography>
            <Link href={'/contact'} variant='button-primary' color={theme.colors.light} >Comencemos tu proyecto</Link>
          </Box>
        </Flex>
      </Container>
    </Section>
  )
}

export default Index
