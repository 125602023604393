// semi-dynamic
import React from "react"
import styled from "styled-components"
import theme from "../../utils/theme"

import Link from "../../components/Link"
import Section from "../../components/Section"
import Container from "../../components/Container"
import Typography from "../../components/Typography"
import { Flex, Box } from "../../components/Grid"
import { GatsbyImage } from "gatsby-plugin-image"

const Card = styled.div`
  position: relative;
  width: 100%;
  min-height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 0 30px -5px rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease-in-out;
  overflow: hidden !important;
  &:hover {
    transform: scale(1.05);
  }
`

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 32px;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  background: transparent;
  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
  }
  &:hover {
    opacity: 1;
    &::before {
      background: rgba(0,0,0,0.6);
      opacity: 1;
      z-index: -1;
    }
  }
`

const StyledImage = styled(GatsbyImage)`
  width: 100%,
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  z-index: -1;
`

const Portfolio = ({ contents }) => {
  return (
    <Section>
      <Container>
        <Box px={0} textAlign='center'>
          <Typography tag="span" mb={3} color={theme.colors.primary} fontSize={3} fontFamily={1} textTransform="uppercase" letterSpacing="2px" >Portafolio</Typography>
          <Typography tag="h3">Conoce nuestro trabajo</Typography>
          <Typography tag="p" mb={6}>Nuestra manera de trabajar tiene impresa una marca<br/> de calidad que podrás encontrar en nuestros proyectos.</Typography>
        </Box>
        <Flex>
          {contents.items.map((element, i) => (
            <Box py={3} width={['100%','100%',1/2]} key={i} position='relative' >
              <Link href={element.link} style={{cursor: !element.link && 'default'}}>
                <Card content={element.description}>
                  <StyledImage image={element.image} alt={element.title} />
                  <Wrapper >
                    <Typography tag='h3' color={theme.colors.light} >{element.title}</Typography>
                    <Typography tag='p' color={theme.colors.light} fontFamily={0} >{element.services}</Typography>
                    {element.link && <Link href={element.link} variant='underline-crooked' color={theme.colors.light} >{element.button}</Link>}
                  </Wrapper>
                </Card>
              </Link>
            </Box>
          ))}
        </Flex>
      </Container>
    </Section>
  )
}

export default Portfolio
