import React from 'react'
import styled from 'styled-components'
import theme from '../utils/theme'

import Link from './Link'
import { Flex } from './Grid'

import { SiWhatsapp, SiGmail, SiFacebook, SiInstagram } from 'react-icons/si'

const Item = styled.div`
  padding: ${p => p.flex ? `5px 10px` : `0 10px` };
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border-bottom 0.2s;
  color: ${p => p.color};
`

const MediaShare = ({ flex = true, size = '16px', color = theme.colors.dark }) => {
  return (
    <Flex display={!flex && 'block'}>
      <Item color={color} flex={!flex} ><Link href={'https://api.whatsapp.com/send?phone=528113213903&text=Hola%20Allency,%20quisiera%20recibir%20información.'} variant='scale' target='_blank' ><SiWhatsapp fontSize={size} style={{position: 'relative', top: '4px'}} /></Link></Item>
      <Item color={color} flex={!flex} ><Link href={'mailto:hola@allency.com.mx'} variant='scale' target='_blank' ><SiGmail fontSize={size} style={{position: 'relative', top: '4px'}} /></Link></Item>
      <Item color={color} flex={!flex} ><Link href={'https://www.facebook.com/allencymx/'} variant='scale' target='_blank' ><SiFacebook fontSize={size} style={{position: 'relative', top: '4px'}} /></Link></Item>
      <Item color={color} flex={!flex} ><Link href={'https://instagram.com/allencymx?utm_medium=copy_link'} variant='scale' target='_blank' ><SiInstagram fontSize={size} style={{position: 'relative', top: '4px'}} /></Link></Item>
      {/* <Item color={color} flex={!flex} ><Link href={'/'} variant='scale' target='_blank' ><SiYoutube fontSize={size} style={{position: 'relative', top: '4px'}} /></Link></Item> */}
    </Flex>
  )
}

export default MediaShare