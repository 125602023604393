// semi-dynamic
import React from "react"
import styled from "styled-components"
import theme from "../../utils/theme"

import Section from "../../components/Section"
import Container from "../../components/Container"
import Typography from "../../components/Typography"
import { Flex, Box } from "../../components/Grid"
import { GatsbyImage } from "gatsby-plugin-image"

const Card = styled.div`
  position: relative;
  width: 100%;
  min-height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 0 30px -5px rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease-in-out;
  overflow: hidden !important;
  &::before {
    position: absolute;
    content: '${p => p.content}';
    width: 100%;
    height: 100%;
    background: transparent;
    color: ${theme.colors.light};
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
    z-index: 1;
  }
  &:hover {
    transform: scale(1.05);
    &::before {
      background: rgba(255,255,255,0.4);
      opacity: 1;
    }
  }
`

const StyledImage = styled(GatsbyImage)`
  width: 100%,
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
`

const Profiles = ({ contents }) => {
  return (
    <Section>
      <Container>
        <Box px={0} textAlign='center'>
          <Typography tag="span" mb={3} color={theme.colors.primary} fontSize={3} fontFamily={1} textTransform="uppercase" letterSpacing="2px" >
            Perfiles
          </Typography>
          <Typography tag="h3">Perfiles de aliados</Typography>
          <Typography tag="p" mb={7}>Generamos resultados que van<br/> de acuerdo al perfil de nuestros clientes.</Typography>
        </Box>
        <Flex>
          {contents.items.map((element, i) => (
            <Box px={[0,3,3]} width={['100%','100%',1/3]} textAlign='center' key={i} >
              <Card content={element.description}><StyledImage image={element.image} alt={element.alt} /></Card>
              <Typography tag='p' mt={3} fontFamily={1} >{element.name}</Typography>
            </Box>
          ))}
        </Flex>
      </Container>
    </Section>
  )
}

export default Profiles