// dynamic
import React, { useState } from "react"
import styled from "styled-components"
import theme from "../../utils/theme"

import Link from "../../components/Link"
import Section from "../../components/Section"
import Container from "../../components/Container"
import Typography from "../../components/Typography"
import { Flex, Box } from "../../components/Grid"

import teamwork from '../../images/icons/why-teamwork.svg'
import results from '../../images/icons/why-results.svg'
import strategy from '../../images/icons/why-strategy-plan.svg'
import quality from '../../images/icons/why-quality.svg'
import { GatsbyImage } from "gatsby-plugin-image"

const StyledSection = styled(Section)`
  position: relative;
  &::before {
    position: absolute;
    content: '';
    top: 0;
    right: -25%;
    transform: translateX(25%) rotate(-45deg);
    background: ${theme.colors.extended.gray400};
    background-size: cover;
    background-repeat: no-repeat;
    width: 800px;
    height: 1800px;
    filter: brightness(1.2);
    opacity: 0.3;
    border-radius: 400px;
    z-index: -1;
  }
`

const Wrapper = styled.div`
  position: relative;
  width: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  &::before {
    position: absolute;
    content: '';
    width: 2px;
    height: calc(100% - 50px);
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: ${theme.colors.extended.gray200};
    z-index: -2;
  }
  @media (max-width: 831px) {
    position: relative;
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    &::before {
      position: absolute;
      content: '';
      width: calc(100% - 50px);
      height: 2px;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background: ${theme.colors.extended.gray200};
      z-index: -2;
    }
  }
`

const Item = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  background: ${theme.colors.light};
  border: 1px solid ${theme.colors.primary};
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-size: 0px;
  -webkit-tap-highlight-color: transparent;

  img { filter: brightness(0.2); }

  &:hover {
    transform: scale(1.05);
    font-size: 14px;
    &::after {
      top: -20px;
    }
  }
  &.active {
    font-size: 14px;
    border-color: ${theme.colors.secondary.green700};
    animation: pulse 1.5s infinite ease-in-out;
    background: ${theme.colors.primary};
    img { filter: brightness(1); }
    @keyframes pulse {
      0% {transform: scale(1)}
      50% {transform: scale(1.05)}
      100% {transform: scale(1)}
    }
  }
  @media (max-width: 832px) {
    &::after {
      position: absolute;
      content: '${p => p.name}';
      width: 100px;
      height: 100%;
      left: -25px;
      top: -20px;
      text-align: center;
      text-transform: uppercase;
    }
  }
`

const Img = styled.img`
  width: 50px;
  height: 50px;
  padding: 8px;
  // border-radius: 50%;
  border: 1px solid transparent;
  fill: red;
`

const WhyusSection = ({ contents, hideLinks }) => {
  const [active, setActive] = useState(0)
  return (
    <StyledSection overflow='hidden' id='about-us'>
      <Container >
        <Flex justifyContent='space-between' minHeight={['auto','auto','300px']}>
          <Box px={0} pr={[0,0,3]} mb={[3,3,0]} width={['100%','100%','50px']}>
            <Flex height={'100%'}>
              <Box px={0} pr={3} mb={[3,3,0]} width={['100%','100%','50px']}>
                <Wrapper>
                  <Item name={'Estrategia'} className={active === 0 && 'active'} onClick={() => setActive(0)}><Img src={strategy} alt='Strategy' width='100%' /></Item>
                  <Item name={'Resultados'} className={active === 1 && 'active'} onClick={() => setActive(1)}><Img src={results} alt='Results' width='100%' /></Item>
                  <Item name={'Equipo'} className={active === 2 && 'active'} onClick={() => setActive(2)}><Img src={teamwork} alt='Teamwork' width='100%' /></Item>
                  <Item name={'Calidad'} className={active === 3 && 'active'} onClick={() => setActive(3)}><Img src={quality} alt='Quality' width='100%' /></Item>
                </Wrapper>
              </Box>
            </Flex>
          </Box>
          <Box px={[0,0,6]} py={[3,3,0]} width={['100%','100%','calc(100% - 50px)']}>
            <Flex justifyContent='space-between'>
              <Box px={0} mb={[5,0,0]} width={['100%','70%','45%']}>
                <Typography tag="h5" mb={3} color={theme.colors.primary} fontSize={4} fontFamily={1} textTransform="uppercase" letterSpacing="2px" >
                  {contents.reasons[active].title}
                </Typography>
                <Typography tag="p" mb={5} brake={true} dangerouslySetInnerHTML={{ __html: contents.reasons[active].description }} />
                {!hideLinks && <Link href={contents.reasons[active].link} variant='button-primary' >{contents.reasons[active].button}</Link>}
              </Box>
              <Box px={0} width={['100%','30%','40%']} height='100%'>
                <GatsbyImage image={contents.reasons[active].image} alt={contents.reasons[active].title} />
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Container>
    </StyledSection>
  )
}

export default WhyusSection