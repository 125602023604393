import React, { useEffect } from "react"
import { graphql, useStaticQuery } from 'gatsby'
// import styled from "styled-components"
// import theme from "../utils/theme"

import Link from "../components/Link"
// import Modal from "../components/Modal"
import Section from "../components/Section"
import Container from "../components/Container"
// import Typography from "../components/Typography"
import { Box } from "../components/Grid"
import { GatsbyImage } from "gatsby-plugin-image"

const Index = ({ contents }) => {
  const dataImage = useStaticQuery(graphql`
    query {
      notAvailable: file(relativePath: { eq: "stock/allency-in-construction-spanish.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `)

  const notAvailable = dataImage.notAvailable.childImageSharp.gatsbyImageData

  useEffect(() => {
    window.scrollTo(0, 180)
  }, [])
  

  return (
    <Section>
      <Container>
        <Box px={0} height='100vh' display='flex' alignItems='center' justifyContent='center'>
          <GatsbyImage image={notAvailable} alt='not available' />
        </Box>
        <Box px={0} textAlign='center'>
          <Link href='/contact' variant='button-primary'>Mientras tanto puedes intentar contactandonos</Link>
        </Box>
      </Container>
    </Section>
  )
}

export default Index
