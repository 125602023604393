// dynamic
import React from "react"
import theme from "../../utils/theme"

import Section from "../../components/Section"
import Container from "../../components/Container"
import Typography from "../../components/Typography"
import { Box } from "../../components/Grid"
import { GatsbyImage } from "gatsby-plugin-image"

const Case = ({ contents }) => {
  return (
    <Section>
      <Container>
        <Box px={[0,0,8,null,9]} textAlign='center'>
          <Typography tag="h3">{contents.title}</Typography>
          <Typography tag="p" mb={3} color={theme.colors.primary} fontSize={3} fontFamily={1} textTransform="uppercase" letterSpacing="2px" >{contents.services}</Typography>
          <Typography tag="p" mb={6} dangerouslySetInnerHTML={{ __html: contents.description }}/>
        </Box>
        <Box px={0}>
          {contents.images.map((image, i) => (
            <GatsbyImage image={image} key={i} alt={contents.title} />
          ))}
        </Box>
      </Container>
    </Section>
  )
}

export default Case
