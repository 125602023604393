// semi-dynamic
import React from "react"
import styled from "styled-components"
import theme from "../../utils/theme"
import { GatsbyImage } from "gatsby-plugin-image"

import Link from "../../components/Link"
import Slider from "../../components/Slider"
import Section from "../../components/Section"
import Container from "../../components/Container"
import Typography from "../../components/Typography"
import { Flex, Box } from "../../components/Grid"

const LeftCard = styled.div`
  width: 55%;
  height: 600px;
  // padding: 32px;
  border-radius: 8px;
  background: ${theme.colors.extended.gray200};
  box-shadow: 0 0 30px -5px rgba(0, 0, 0, 0.4);
  overflow: hidden !important;
  @media (max-width: 639px) {
    width: 100%;
    height: 200px;
  }
`

const RightCard = styled.div`
  width: 45%;
  height: 540px;
  padding: 64px 48px;
  border-radius: 0 8px 8px 0;
  background: ${theme.colors.extended.gray800};
  box-shadow: 0 0 30px -5px rgba(0, 0, 0, 0.4);
  @media (max-width: 639px) {
    width: 90%;
    height: 100%;
    padding: 20px 24px;
    border-radius: 0 0 8px 8px;
  }
`

const Image = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 10% 0;
`

const PortfolioSection = ({ contents }) => {
  return (
    <Section overflow='hidden' id='portfolio'>
      <Container variant="narrow">
        <Box px={0} textAlign='center'>
          <Typography tag="span" mb={3} color={theme.colors.primary} fontSize={3} fontFamily={1} textTransform="uppercase" letterSpacing="2px" >
            Nosotros
          </Typography>
          <Typography tag="h3">Conoce nuestro trabajo</Typography>
          <Typography tag="p" mb={7}>Nuestra manera de trabajar tiene impresa una marca de calidad<br/> que podrás encontrar en nuestros proyectos.</Typography>
        </Box>
        <Slider toShow={[1,1,1]} toScroll={[1,1,1]} arrows={false} overflow={true}>
          {contents.slides.map((item, i) => (
            <Box px={[2,3,3]} key={i} >
              <Flex  alignItems='center' justifyContent='center'>
                <LeftCard>
                  <Image image={item.image} alt={item.name} />
                </LeftCard>
                <RightCard>
                  <Typography tag='h3' color={theme.colors.light} >{item.name}</Typography>
                  <Typography tag='p' color={theme.colors.light} fontFamily={0} >{item.title}</Typography>
                  <Typography tag='p' color={theme.colors.light} >{item.description}</Typography>
                  <Link href={item.link} variant='button-light-ghost' color={theme.colors.light} >{item.button}</Link>
                </RightCard>
              </Flex>
            </Box>
          ))}
        </Slider>
      </Container>
    </Section>
  )
}

export default PortfolioSection