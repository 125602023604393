import React from 'react'
import styled from 'styled-components'
import css from '@styled-system/css'
import theme from '../utils/theme'
import { space, layout, variant, flexbox, typography } from 'styled-system'


const StyledButton = styled.button`
  text-decoration: none;
  padding: 10px 12px;
  transition: all 0.1s ease-in-out;
  overflow: hidden;
  position: relative;

  &:disabled {
    opacity: 0.25;
    &:hover {
      transform: none;
    }
  }

  @keyframes pulseBlue {
    0% {-moz-box-shadow: 0 0 0 0 rgba(125, 217, 255, 0.4); box-shadow: 0 0 0 0 rgba(125, 217, 255, 0.4);}
    70% {-moz-box-shadow: 0 0 0 10px rgba(125, 217, 255, 0); box-shadow: 0 0 0px 20px rgba(125, 217, 255, 0);}
    100% {-moz-box-shadow: 0 0 0 0 rgba(125, 217, 255, 0); box-shadow: 0 0 0 0 rgba(125, 217, 255, 0);}
  }
  @keyframes pulseGreen {
    0% {-moz-box-shadow: 0 0 0 0 rgba(44, 204, 107, 0.4); box-shadow: 0 0 0 0 rgba(44, 204, 107, 0.4);}
    70% {-moz-box-shadow: 0 0 0 10px rgba(44, 204, 107, 0); box-shadow: 0 0 0px 20px rgba(44, 204, 107, 0);}
    100% {-moz-box-shadow: 0 0 0 0 rgba(44, 204, 107, 0); box-shadow: 0 0 0 0 rgba(44, 204, 107, 0);}
  }
  ${css({
    lineHeight: 0,
    borderRadius: '6px',
  })}
  ${space}
  ${flexbox}
  ${layout}
  ${typography}
  ${variant({
    variants: {
      default: {
        background: `${theme.colors.extended.gray500}`,
        color: '#fff',
        border: 'none',
        '&:hover': {
          transform: 'scale(1.03)',
          animation: 'none',
        },
      },
      primary: {
        background: `${theme.colors.primary}`,
        color: '#fff',
        border: 'none',
        '&:hover': {
          transform: 'scale(1.03)',
          animation: 'none',
        },
      },
      blue: {
        background: `${theme.colors.primary}`,
        color: '#fff',
        border: 'none',
        '&:hover': {
          transform: 'scale(1.03)',
          animation: 'none',
        },
      },
      green: {
        background: `${theme.colors.secondary.green500}`,
        color: '#fff',
        border: 'none',
        '&:hover': {
          transform: 'scale(1.03)',
          animation: 'none',
        },
      },
      yellow: {
        background: `${theme.colors.secondary.yellow700}`,
        color: '#fff',
        border: 'none',
        '&:hover': {
          transform: 'scale(1.03)',
          animation: 'none',
        },
      },
      red: {
        background: `${theme.colors.secondary.red700}`,
        color: '#fff',
        border: 'none',
        '&:hover': {
          transform: 'scale(1.03)',
          animation: 'none',
        },
      },
    },
  })}
  
`

const Button = ({ children, tag, ...props }) => {
  return (
    <StyledButton as={tag} {...props}>
      {children}
    </StyledButton>
  )
}

StyledButton.defaultProps = {
  display: 'block',
  mb: 3,
  mt: 3,
}

export default Button

