// static
import React from "react"

import Section from "../../components/Section"
import Container from "../../components/Container"
import Typography from "../../components/Typography"
import { Flex, Box } from "../../components/Grid"
import { GatsbyImage } from "gatsby-plugin-image"

const Index = ({ contents }) => {
  return (
    <Section>
      <Container>
        <Flex justifyContent='space-between'>
          <Box px={[0]} width={['100%','100%','40%']} display='flex' alignItems='center'>
            <Box px={0}>
              <Typography tag="h3">Tus aliados en <br/>publicidad</Typography>
              <Typography tag="p" >En Allency generamos soluciones integrales que van de acuerdo a las necesidades de nuestros clientes, que parten desde startups, negocios en crecimiento, eventos, empresas y marcas posicionadas. </Typography>
              <Typography tag="p" >A cada uno de nuestros aliados, le brindamos el compromiso y los resultados que buscan dentro de su estrategia integral de publicidad y marketing.  </Typography>
            </Box>
          </Box>
          <Box px={3} width={['100%','100%','50%']}>
            <GatsbyImage image={contents.allies.image} alt={'Our allies'} style={{borderRadius: '12px'}}/>
          </Box>
        </Flex>
      </Container>
    </Section>
  )
}

export default Index
