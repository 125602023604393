// static
import React, { useState } from 'react'
import styled from 'styled-components'
import theme from '../../utils/theme'

import Link from '../../components/Link'
import Modal from '../../components/Modal'
import Section from '../../components/Section'
import Container from '../../components/Container'
import Typography from '../../components/Typography'
import MediaShare from '../../components/MediaShare'
import { Box } from '../../components/Grid'

import hero from '../../images/banners/allency_banner-2.png'
import baseButton from '../../images/stock/hero-center-button-base-path.svg'
import play from '../../images/icons/play-icon-alt.svg'

const StyledSection = styled(Section)`
  position: relative;
  // margin-bottom: 40px;
  // padding: 294px 0;
  padding: 144px 0 294px 0;
  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url(${hero});
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
    z-index: -2;
  }
  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 70px;
    bottom: 0;
    background: url(${baseButton});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
  }
  @media (max-width: 640px) {
    padding: 94px 0 134px 0;
    &::before {
      background-position: top center;
      opacity: 0.5;
    }
  }
`

const Wrapper = styled.div`
  position: absolute;
  bottom: 38px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  cursor: pointer;
  // box-shadow: 0 0 10px rgba(255,255,255,0.4);
  img {
    width: 20px;
    height: 20px;
    margin-left: 2px;
  }
  &:hover {
    transform: translateX(-50%) scale(1.1);
  }
`

const Media = styled.div`
  position: absolute;
  right: 16px;
  bottom: 12%;
  transform: translateY(-12%);
  width: 40px;
  height: auto;
  &::before {
    position: absolute;
    content: '';
    width: 2px;
    height: 40px;
    background: ${theme.colors.extended.gray800};
    top: -38px;
    left: 50%;
    transform: translateX(-50%);
  }
  @media (max-width: 360px) {
    right: 0;
    &::before {
      display: none;
    }
  }
`

const modalStyles = {
  border: 'none',
  padding: '0',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const HeroSection = () => {
  const [modal, setModal] = useState(false)
  return (
    <StyledSection>
      <Container>
        <Typography tag='h5' >Allency</Typography>
        <Typography tag='h1' spanStyles={{color: theme.colors.primary}} fontSize={['40px','58px']}>Tus aliados <br /> en <span>publicidad</span></Typography>
        <Typography tag='p' mb={5} brake={true} >Brindamos soluciones en tu estrategia <br/>publicitaria.</Typography>
        <Link href={'/start-your-project'} variant='button-primary' >Comencemos tu proyecto</Link>
      </Container>

      <Wrapper onClick={() => setModal(true)}>
        <Box px={0} width='50px' height='50px' background={theme.colors.light} borderRadius='50%' display='flex' alignItems='center' justifyContent='center' >
          <img src={play} alt='play button' />
        </Box>
      </Wrapper>

      <Media>
        <MediaShare flex={false} color={theme.colors.dark} />
      </Media>

      <Modal isOpen={modal} setClose={() => setModal(false)} customStyles={modalStyles}>
        <iframe title='allency' src="https://player.vimeo.com/video/700459503?h=4c9cd76835" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
      </Modal>
    </StyledSection>
  )
}

export default HeroSection