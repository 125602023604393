import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
	html {
	   overflow-y: visible;
	   box-sizing: border-box;
     font-size: 70%;
	}
	*,
	*::before,
	*::after {
	   box-sizing: inherit;
	}
  
`
